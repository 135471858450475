// Brand Colors
$primary: #00B5CB;
$secondary: #00B5CB;
$tertiary: #00B5CB;
// * Color used for all font sizes except the smallest
$foreground-primary: #414141;
$foreground-primary-light: #6e6e6e;
// * Color used for the smallest font size, shadows, icons, dividers, borders, and other elements
$foreground-secondary: #515962;
$foreground-tertiary: #fff;
// * Color used for errors, alerts, emergency, and other important messages
$alert: #e10506;
// * Background colors
$background-primary-light: #EEFBFD;
$background-primary-lighter: #EEFBFD;
$background-neutral: #F5F5F5;
$background-highlight: #fff4d7;
$background-secondary-light:  #f5f5f5;
$background-subtitle-color: rgba(128, 128, 128, 0.502);

// * Border colors
$border-primary: #e9e9e9;

:export {
  brandColorPrimary: $primary;
  brandColorForegroundPrimary: $foreground-primary;
  brandColorForegroundSecondary: $foreground-secondary;
  brandColorAlert: $alert;
}
